import React from 'react'
import '../style/Event.css'
import {BsClock,BsFillGeoAltFill} from 'react-icons/bs'


export default function Event(props){
    return (
        <a className='event' href={props[4]}>
            <div className='imgholder'>
                <img className="img" src={props[5]} />
                <div className='categories'>
                    {
                    props[3].split(',').map((category) => (
                        <div className='category'>{category}</div>
                    ))
                    }
                </div>
            </div>
            <div className='infoevent'>
                <div className='infoeventleft'>
                    <h4>{props[0]}</h4>
                    <div className="place">{<BsFillGeoAltFill className='iconEvent'/>}{props[1]}</div> 
                </div>
                <div className='infoeventright'>
                    <div className="but">-&#62;</div>
                    <div className='time'>{<BsClock className='iconEvent'/>}{props[6]}h</div>
                </div>
            </div>
            
        
        </a>
    )
}
import axios from 'axios';

const baseUrl = `https://gent-event.ardw.be/`;

const useEvents = () => {
  const getAll = async () => {
    const data = await axios.get(baseUrl);
    let da = data.data;
    let nieuwedata = [];
    da.forEach((element) => {
      if (element[0] !== "EVENT") {
        nieuwedata.push(element);
        console.log(element);
      }
    });
    return nieuwedata;
  };

  return {
    getAll,
  };
};

export default useEvents;

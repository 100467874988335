import React from 'react';
import { Link } from 'react-router-dom';
import '../style/Global.css';
import logo from '../images/logosvg.svg';

export  function Navbar() {
    return(
        <header>
            <nav>
                <Link to='/home'>
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>
                </Link>
                <div className="rightnav">
                    <Link to="/events"><div className="tabnav">EVENTS</div></Link>
                    <Link to="https://www.instagram.com/gent.event/"><div className="tabnav butnav">CONTACT</div></Link>
                </div>
        </nav>
       </header>
    )
}

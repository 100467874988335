import React from "react";
import CalenderWoman from "../images/Calender_Woman.png";
import { Link } from "react-router-dom";
import '../style/Landing.css';
import { Helmet } from 'react-helmet-async'

export default function LandingPage() {
    return(
        <>
        <Helmet>
            <title>GENT EVENT | All events & discounts in Ghent, Belgium!</title>
                <meta
                name="description"
                content="All events & discounts for students from Ghent are updated here weekly! Follow us on Instagram @gent.event for more updates!"
                />
            </Helmet>
        <main className="LandingMain">
            <div className="tekstblok">
            <h1>GENT EVENT</h1>
            <div className="quote">WE PLAN, YOU GO</div>
            <div className="uitleg nr1">
                <div>Weekly updates on what’s going on in Ghent.</div>
                <div>Don't forget to follow us on <a className="instagramlink" href="https://www.instagram.com/gent.event/">Instagram</a></div> 

            </div>
                <div className="uitleg nr2">
            
            </div>
            <div className="socials"></div>
            <div className="buttons">
                <Link to="/events">
                    <div className="eventsbutton">EVENTS</div>
                </Link>
                {/* <div>
                    Sign up for our newsletter!
                </div>
                <div>
                    To get weekly updates right into your email.
                </div>
                <div className="signup">
                    <input type="text"className="inputText" />
                    <input type="submit" className="subBut" placeholder="email@mail.com" value="Sign up!" />
                </div> */}
            </div>
                </div>
            <img className="mainpic" src={CalenderWoman} alt="woman calender" />
        </main>
        <div class="line"></div>
        </>
    )
}

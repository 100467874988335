import { useLocation, Link } from 'react-router-dom';
import '../style/Global.css';

export function NotFound() {
  const { pathname } = useLocation();
  return (
    <div className="nietgevonden">
      <div>
        <h1>Pagina niet gevonden</h1>
        <p>
          Er is geen pagina met op deze url
          {pathname}
          , probeer iets anders.
        </p>
       
      </div>
      <Link className="btnnietgevonden" to="/">TERUG</Link>
    </div>
  );
}

import { Routes, Route, Navigate } from "react-router-dom";
import EventList from "./components/EventList";
import LandingPage from "./components/LandingPage";
import { NotFound } from "./components/NotFound";
import { Navbar } from "./components/Navbar";
import { Comingsoon } from "./components/Comingsoon";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Navbar />

      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/home" element={<LandingPage />} />
        {/* <Route path="/events" element={<EventList />} /> */}
        <Route path="/events" element={<EventList />} />
      </Routes>
    </HelmetProvider>
  );
}
export default App;

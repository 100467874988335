import { React, useState, useCallback, useEffect } from "react";
import '../style/Event.css';
import useEvent from "../api/event";
import Event from "./Event";
import Error from './Error';
import Loader from './Loader';
import { Helmet } from 'react-helmet-async'

export default function EventList() {
    const today = new Date();
    let first = today.getDate() - today.getDay() + 1;
    let firstday = new Date(today.setDate(first));
    let secondday = new Date(today.setDate(first + 1));
    let thirdday = new Date(today.setDate(first + 2));
    let fourthday = new Date(today.setDate(first + 3));
    let fifthday = new Date(today.setDate(first + 4));
    const dagen = {
        maandag: {
            dagnaam: "MAANDAG",
            datum: firstday.getDate() + "/" + (firstday.getMonth() + 1),
        },
        dinsdag: {
            dagnaam: "DINSDAG",
            datum: secondday.getDate() + "/" + (secondday.getMonth() + 1),
        },
        woensdag: {
            dagnaam: "WOENSDAG",
            datum: thirdday.getDate() + "/" + (thirdday.getMonth() + 1),
        },
        donderdag: {
            dagnaam: "DONDERDAG",
            datum: fourthday.getDate() + "/" + (fourthday.getMonth() + 1),
        },
        vrijdag: {
            dagnaam: "VRIJDAG",
            datum: fifthday.getDate() + "/" + (fifthday.getMonth() + 1),
        }
    }

    const vandaag = new Date();
    const[currentday, setCurrentday] = useState(vandaag.getDate() + "/" + (vandaag.getMonth() + 1));
    const [currentdayname, setCurrentdayname] = useState("");
    const [activeBol, setActiveBol] = useState(vandaag.getDay() === 1 ? "MAANDAG": vandaag.getDay() === 2 ? "DINSDAG": vandaag.getDay() === 3 ? "WOENSDAG": vandaag.getDay() === 4 ? "DONDERDAG": vandaag.getDay() === 5 ? "VRIJDAG": "MAANDAG");
    const [events , setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tonendeEventen, setTonendeEventen] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");

    const eventApi = useEvent();

    const refreshEvents = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await eventApi.getAll();
            setCurrentdayname(vandaag.getDay() === 1 ? "MAANDAG": vandaag.getDay() === 2 ? "DINSDAG": vandaag.getDay() === 3 ? "WOENSDAG": vandaag.getDay() === 4 ? "DONDERDAG": vandaag.getDay() === 5 ? "VRIJDAG": "MAANDAG");
            setEvents(data);
        
        } catch (error) {
            console.error(error);
            setError(error);
          } finally {
            setLoading(false);
          }
      }, []);
    
      useEffect(() => {
        refreshEvents();
      }, [refreshEvents]);

      const filterEventen = async (dag) => {
        let result = [];
      
        for (let i = 0; i < events.length; i++) {
          if (events[i][2] === dag && (selectedCategory === "All" || events[i][3] === selectedCategory)) {
            result.push(events[i]);
          }
        }
      
        setTonendeEventen(result);
      };
      useEffect(() => {
        filterEventen(currentdayname);
        console.log(selectedCategory);
      }, [currentdayname, selectedCategory]);
    
    function DagStuk({ dag }) {
        return (
            <div className="weekdag" id={dag} onClick={() => {setCurrentday(dag.datum); setCurrentdayname(dag.dagnaam); setActiveBol(dag.dagnaam)}}>
                <div className={activeBol === dag.dagnaam ? "gevuld":"boll"}></div>
                <div className={currentdayname === dag.dagnaam ? "check" : ""}> {dag.dagnaam}</div>        
            </div>
        )
    }

    function FilterStuk({filternaam}){
        return (
            <div className="filter">
                  <div className={activeBol === filternaam ? "gevuld":"boll"}></div>
                <div className="filternaam">{filternaam}</div>
            </div>
        )
    }

    return(
        <>
         <Helmet>
            <title>GENT EVENT | Calendar weekly events!</title>
                <meta
                name="description"
                content="All events & discounts for students from Ghent are updated here weekly! Follow us on Instagram @gent.event for more updates!"
                />
            </Helmet>
        <main className="eventmain" id="mainevent">
        <h3>{currentdayname + " " +  currentday}</h3>
            <div className="weekwrapper">
                <div className="leftdays" id="thedays">
                    <div className="weekdagenwrap">
                    {Object.keys(dagen).map((dag) => (
                        <DagStuk key={dag} dag={dagen[dag]} />
                    ))}
                     </div>
                    <div className="categoryFilter">
                        <div className="categoryFilterTitle">Filter by category</div>
                        <select  value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className="categoryFilterSelect" name="category" id="category" >
                            <option value="All">All</option>
                            <option value="Food">Food</option>
                            <option value="Music">Music</option>
                            <option value="Party">Party</option>
                            <option value="Culture">Culture</option>
                            <option value="Sport">Sport</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                <div className="rightevents" id="alleevents">
                <Error error={error} />
                <Loader loading={loading} />
                {
                    tonendeEventen.length === 0 && !loading && !error && 
                          <div data-cy="event_error" className="alert alert-info">
                            No events founds.
                          </div>
                    
                      
                }
                {tonendeEventen.map((event) => (
                   !loading && !error && < Event {...event} />
                ))}
                </div>
            </div>
         
        </main>
        <footer>
            <div className="footer">   
            </div>
        </footer>
      </>
    )
}

import React from 'react'
import '../style/Global.css'

export default function Loader({ loading }) {
    if (loading) {
    return (
        <div id="ring" className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
    )
    }
    return null;
}